
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import authService from '@/services/auth'

interface IMenuItem {
  icon: string
  text: string
  to: string
  requiredRoles?: Array<string>
}
export default Vue.extend({
  name: 'MedicoHeader',
  data: () => ({
    assinaturaDialog: false,
    items: [
      { icon: 'mdi-text-box-check-outline', text: 'Agendamentos', to: 'agendamentos', requiredRoles: ['ocupacional'] },
      { icon: 'mdi-draw', text: 'Assinatura', to: 'assinatura' },
      { icon: 'mdi-clock-outline', text: 'Agenda médica', to: 'agenda-medica', requiredRoles: ['ocupacional'] },
      {
        icon: 'mdi-text-box-check-outline',
        text: 'Agendamentos',
        to: 'agendamentos-ambulatoriais',
        requiredRoles: ['nutricional', 'psicologico'],
      },
      {
        icon: 'mdi-clock-outline',
        text: 'Agenda médica',
        to: 'agenda-ambulatorial',
        requiredRoles: ['nutricional', 'psicologico'],
      },
    ],
  }),
  computed: {
    ...mapGetters('medico', { medico: 'medico' }),
    allowHeaderMenu() {
      if (this.$route.name === 'login') return

      return true
    },
  },
  methods: {
    showMenuItem(item: IMenuItem) {
      if (item.requiredRoles) {
        return item.requiredRoles.includes(this.medico.tipo_atendimento)
      }
      return true
    },
    ...mapActions('medico', ['resetMedico', 'resetCustomizacao']),
    goTo(route: string) {
      if (this.$route.name !== route) {
        this.$router.push({ name: route })
      }
    },
    logout() {
      this.$logoutDatadogUser()
      authService.logout()
      this.resetMedico()
      this.$router.push('/login')
    },
  },
  created() {
    this.$vuetify.theme.themes.light.primary = this.medico.customizacao.color_theme
  },
})
