import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store/index'
import AuthService from '@/services/auth'
import IMedico from '../models/IMedico'

Vue.use(VueRouter)

const homeRoute = {
  ocupacional: 'agendamentos',
  nutricional: 'agendamentos-ambulatoriais',
  psicologico: 'agendamentos-ambulatoriais',
}

const medico = store.getters['medico/medico'] as IMedico

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/registrar',
    name: 'registrar',
    component: () => import('@/views/RegistrarView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import('@/views/ErrorPage.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/',
    redirect: () => {
      return homeRoute[medico.tipo_atendimento] || 'login'
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/agendamentos',
    name: 'agendamentos',
    component: () => import('@/views/AgendamentosView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (medico.tipo_atendimento !== 'ocupacional') {
        next({ name: 'agendamentos-ambulatoriais' })
      } else {
        next()
      }
    },
  },
  {
    path: '/assinatura',
    name: 'assinatura',
    component: () => import('@/views/AssinaturaView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/assinatura/upload',
    name: 'assinatura-upload',
    component: () => import('@/views/UploadAssinaturaView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/agenda-medica',
    name: 'agenda-medica',
    component: () => import('@/views/AgendaMedicaView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/agenda-ambulatorial',
    name: 'agenda-ambulatorial',
    component: () => import('@/views/AgendaAmbulatorial.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/agendamentos-ambulatoriais',
    name: 'agendamentos-ambulatoriais',
    component: () => import('@/views/AgendamentosAmbulatoriaisView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (medico.tipo_atendimento === 'ocupacional') {
        next({ name: 'agendamentos' })
      } else {
        next()
      }
    },
  },
  {
    path: '/meeting-ocupacional/:id',
    name: 'meeting-ocupacional',
    component: () => import('@/views/MeetingOcupacionalView.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/meeting-ambulatorial/:id',
    name: 'meeting-ambulatorial',
    component: () => import('@/views/MeetingAmbulatorialView.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const routeExists = routes.some(route => route.name === to.name)
  if (!routeExists) {
    // Se a rota não existir, redirecione para a página de erro 404
    next({ name: 'notFound' })
  } else if (to.meta?.requiresAuth === true) {
    if (AuthService.isAuthenticated()) {
      next()
    } else {
      next({ name: 'login' })
    }
  } else if (to.name === 'login' || to.name === 'registrar') {
    if (AuthService.isAuthenticated()) {
      next({
        name: homeRoute[medico.tipo_atendimento],
        query: { partner: to.query.partner, name: to.query.name, hash: to.query.hash },
      })
    } else {
      next()
    }
  }
})

export default router
