import { Commit } from 'vuex'
import IMedico from '../../models/IMedico'
import ICustomizacao from '../../models/ICustomizacao'

export const SET_MEDICO = 'SET_MEDICO'
export const RESET_STATE = 'RESET_STATE'
export const SET_CUSTOMIZACAO = 'SET_CUSTOMIZACAO'

const getDefaultState = () => {
  return {
    id: 0,
    atendimento_ambulatorial: null,
    nome: true,
    email: '',
    tipo_atendimento: '',
    customizacao: {
      color_theme: '#006bf2',
      url_logo: '/plataforma-limer.png',
    },
  }
}
const state = getDefaultState()

const mutations = {
  [RESET_STATE]: (state: IMedico) => Object.assign(state, getDefaultState()),
  [SET_CUSTOMIZACAO]: (
    state: IMedico,
    { workspace, colorTheme, urlLogo }: { workspace: string; colorTheme: string; urlLogo: string }
  ) => {
    state.customizacao.workspace = workspace
    state.customizacao.color_theme = colorTheme
    state.customizacao.url_logo = urlLogo
  },
  [SET_MEDICO]: (
    state: IMedico,
    {
      medico,
      mainCustomizacao,
      customizacoes,
    }: { medico: IMedico; mainCustomizacao: ICustomizacao; customizacoes: ICustomizacao[] }
  ) => {
    state.id = medico.id
    state.atendimento_ambulatorial = medico.atendimento_ambulatorial
    state.nome = medico.nome
    state.email = medico.email
    state.tipo_atendimento = medico.tipo_atendimento
    state.customizacao = mainCustomizacao
    state.customizacoes = customizacoes
  },
}

const getters = {
  medico: (state: IMedico) => state,
}
const actions = {
  resetMedico: ({ commit }: { commit: Commit }) => {
    commit(RESET_STATE)
  },
  setMedico(
    { commit }: { commit: Commit },
    {
      medico,
      mainCustomizacao,
      customizacoes,
    }: { medico: IMedico; mainCustomizacao: ICustomizacao; customizacoes: ICustomizacao[] }
  ) {
    commit(SET_MEDICO, { medico, mainCustomizacao, customizacoes })
  },
  setCustomizacao({ commit }: { commit: Commit }, customizacao: ICustomizacao) {
    commit(SET_CUSTOMIZACAO, customizacao)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
